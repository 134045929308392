import { useState } from 'react'
import './Footer.css'
import useObjectStatusStore from '../../../Store/ObjectStatus'
import { ObjectStatusEnum } from '../../../Store/ObjectStatusEnum'

function Footer() {
    const objectStatus = useObjectStatusStore((state) => state.objectStatus)

    let classStatus = ''
    if (objectStatus === ObjectStatusEnum.warning) classStatus = 'Status StatusWarning'
    if (objectStatus === ObjectStatusEnum.safe) classStatus = 'Status StatusSafe'
    if (objectStatus === ObjectStatusEnum.alert) classStatus = 'Status StatusAlert'

    return (
        <footer className='Footer'>
            <div className={classStatus}>
                <p>stan obiektu:&nbsp;
                    <b>{objectStatus}</b></p>
            </div>
        </footer>
    )
}

export default Footer