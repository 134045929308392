import { Tabs } from 'antd';
import './App.css';
import './shadow.css';
import TopBar from './features/topBar/TopBar';
import TabPane from 'antd/es/tabs/TabPane';
import Graphs from './features/Charts/components/Graphs';
import StatusDashboard from './features/RestrictionsSigns/components/StatusDashboard';
import Footer from './features/Footer/components/Footer';
import useObjectStatusStore from './Store/ObjectStatus';
import { ObjectStatusEnum } from './Store/ObjectStatusEnum';

function App() {

  const setStatus = useObjectStatusStore((state) => state.setStatus)
  setStatus(ObjectStatusEnum.warning)

  return (
    <div className="App">
      <TopBar />
      <div className="App-container">
        <Tabs
          style={{ height: '100%' }}
          type="card"
          size='large'
          defaultActiveKey="1">
          <TabPane tab="KCSiR" key="1">
            <StatusDashboard location='kcsir' />
            <Graphs location='kcsir' />
          </TabPane>
          <TabPane tab="Ratusz" key="2" >
            <StatusDashboard location='ratusz' />
            <Graphs location='ratusz' />
          </TabPane>
        </Tabs>
      </div>
      <Footer />
    </div >
  )
}

export default App;