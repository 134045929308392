
import { useEffect, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { Card, DatePicker, Tabs } from 'antd';
import { DESDataModel } from '../services/DESDataModel';
import Papa from 'papaparse';
import './Graphs.css'

type Props = {
    location: string
}

function Graphs({ location }: Props) {

    let url = ''
    let range = [-10, 10]
    let dataFormat = 'DD.MM.YYYY HH:mm:ss'
    switch (location) {
        case 'kcsir':
            url = 'kwidzyn'
            range = [-8, 1]
            dataFormat = 'DD.MM.YYYY HH:mm:ss'
            break;
        case 'ratusz':
            url = 'kwidzynu'
            range = [-5, 5]
            dataFormat = 'MM/DD/YYYY HH:mm:ss A'
            break;
        default:
            break;
    }

    const [kameraRaw1, setKameraRaw1] = useState<DESDataModel[]>([])
    const [kameraRaw2, setKameraRaw2] = useState<DESDataModel[]>([])
    const [kamera1, setKamera1] = useState<DESDataModel[]>([])
    const [kamera2, setKamera2] = useState<DESDataModel[]>([])

    const { RangePicker } = DatePicker;
    const [dateFromPickerSetValue, setDateFromPickerSetValue] = useState<number>(-1);
    const [dateToPickerSetValue, setDateToPickerSetValue] = useState<number>(-1);

    const [time, setTime] = useState(1);
    useEffect(() => {
        GetData()
        getData2()
        setDateFromPickerSetValue(dayjs().add(-30, 'd').unix())
        setDateToPickerSetValue(dayjs().unix())
    }, [time]);

    useEffect(() => {
        setKamera1(kameraRaw1.filter((data: DESDataModel) => (data.DateTimeOriginalTimeStamp > dateFromPickerSetValue && data.DateTimeOriginalTimeStamp < dateToPickerSetValue)))
    }, [kameraRaw1, dateFromPickerSetValue, dateToPickerSetValue]);

    useEffect(() => {
        setKamera2(kameraRaw2.filter((data: DESDataModel) => (data.DateTimeOriginalTimeStamp > dateFromPickerSetValue && data.DateTimeOriginalTimeStamp < dateToPickerSetValue)))
    }, [kameraRaw2, dateFromPickerSetValue, dateToPickerSetValue]);

    // async function getData1() {
    //     const response = await fetch(`../assets/${url}1.csv`)
    //     const reader = response.body?.getReader()
    //     const result = await reader?.read() // raw array
    //     const decoder = new TextDecoder('utf-8')
    //     const csv = decoder.decode(result?.value) // the csv text
    //     const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    //     const data = (results.data as DESDataModel[]).map(x => {
    //         let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
    //         x.dyMm = dmYInt;
    //         x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormat).unix()
    //         return x;
    //     })
    //     setKameraRaw1(data)
    // }

    async function GetData() {
        const results = Papa.parse(`../assets/${url}1.csv`, {
            header: true, download: true, dynamicTyping: true, skipEmptyLines: true, complete: function
                complete(results, file) {
                console.log('Parsing complete:', results, file);
                const data = (results.data as DESDataModel[]).map(x => {
                    let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
                    x.dyMm = dmYInt;
                    x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormat).unix()
                    return x;
                })
                console.log(data)
                setKameraRaw1(data)
            },
        });
    }

    async function fetchCsv() {
        const response = await fetch(`../assets/${url}1.csv`);
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = await decoder.decode(result.value);
        return csv;
    }


    async function getData2() {
        const response = await fetch(`../assets/${url}2.csv`)
        const reader = response.body?.getReader()
        const result = await reader?.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result?.value) // the csv text
        const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
        const data = (results.data as DESDataModel[]).map(x => {
            let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
            x.dyMm = dmYInt;
            x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormat).unix()
            return x;
        })
        setKameraRaw2(data)
    }

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Ostatnie 7 dni', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Ostatnie 14 dni', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Ostatnie 30 dni', value: [dayjs().add(-30, 'd'), dayjs()] },
        ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            const from = dates[0]?.startOf('day').unix();
            const to = dates[1]?.endOf('day').unix();
            if (from && to) {
                setDateFromPickerSetValue(from);
                setDateToPickerSetValue(to);
            }
        } else {
            console.log('Clear');
        }
    };

    return (
        <div className='graphs'>
            <div>
                <RangePicker onChange={onRangeChange} presets={rangePresets} defaultPickerValue={[dayjs().add(-30, 'd'), dayjs()]} defaultValue={[dayjs().add(-30, 'd'), dayjs()]} />
            </div>
            <Card className='card'>
                <h1>Kamera 1 przemieszczenie pionowe</h1>
                <ResponsiveContainer width="95%" height={500}>
                    <LineChart
                        data={kamera1}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="DateTimeOriginal" />
                        <YAxis type="number" domain={range} />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
            <Card className='card'>
                <h1>Kamera 1 przemieszczenie poziome</h1>
                <h4>Wartości dodatnie obiekt przesuwa się w prawo, wartości ujemne obiekt przesuwa się w lewo</h4>

                <ResponsiveContainer width="95%" height={500}>
                    <LineChart
                        data={kamera1}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="DateTimeOriginal" />
                        <YAxis type="number" domain={range} />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="dxMm" stroke="#ff7300" yAxisId={0} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
            <Card className='card'>
                <h1>Kamera 2 przemieszczenie pionowe</h1>
                <ResponsiveContainer width="95%" height={500}>
                    <LineChart
                        data={kamera2}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="DateTimeOriginal" />
                        <YAxis type="number" domain={range} />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
            <Card className='card'>
                <h1>Kamera 2 przemieszczenie poziome</h1>
                <h4>Wartości dodatnie obiekt przesuwa się w prawo, wartości ujemne obiekt przesuwa się w lewo</h4>

                <ResponsiveContainer width="95%" height={500}>
                    <LineChart
                        data={kamera2}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="DateTimeOriginal" />
                        <YAxis type="number" domain={range} />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="dxMm" stroke="#ff7300" yAxisId={0} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        </div>
    )
}

export default Graphs