
import { create } from 'zustand'
import { ObjectStatusEnum } from './ObjectStatusEnum'

type State = {
    logo: string,
    objectStatus: ObjectStatusEnum
    setStatus: (status: ObjectStatusEnum) => void
}

const useObjectStatusStore = create<State>((set) => ({
    logo: '',
    objectStatus: ObjectStatusEnum.none,
    setStatus: (status: ObjectStatusEnum) => set((state) => ({ ...state, objectStatus: status })),
}))

export default useObjectStatusStore


