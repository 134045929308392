import './Logo.css'

function Logo(props) {
    const clientLogo = props.clientLogo;
    return (
        <div className="Logo">
            <img className='IoTConstructionLogo' alt='IoT Construction' src={process.env.PUBLIC_URL + '/logo.png'} />
            <img className='LogoClient' alt='Logo klienta' src={process.env.PUBLIC_URL + '/' + clientLogo} />
        </div>
    )
}

export default Logo