import './StatusButton.css'

function StatusButton(props) {
    const textDisplay = props.textDisplay;
    const className = props.className;

    return (
        <div className={`StatusButton ${className}`}>
            <p>{textDisplay}</p>
        </div>
    )
}

export default StatusButton