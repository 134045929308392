import LocationView from "../../LocationView/components/LocationView";
import ProhibitionLimitSign from "./ProhibitionLimitSign";
import './StatusDashboard.css'

type Props = {
    location: string
}

function StatusDashboard({ location }: Props) {

    let imageurl = ''
    switch (location) {
        case 'kcsir':
            imageurl = process.env.PUBLIC_URL + '/assets/kcsir.jpg'
            break;
        case 'ratusz':
            imageurl = process.env.PUBLIC_URL + '/assets/kwidzynRatusz.jpg'
            break;
        default:
            break;
    }

    return (
        <div className="StatusDashboard">
            <div className="side">
                <LocationView imageurl={imageurl} />
            </div>
            <div className="side">
                <div className="RestrictionsText">
                    <p>Obowiązujące ograniczenia</p>
                </div>
                {/* <div className="RestrictionsSigns">
                    <ProhibitionLimitSign textDisplay={'10'} />
                    <ProhibitionLimitSign textDisplay={'88'} textSubDisplay={'t'} />
                </div> */}
            </div>

        </div >
    )
}

export default StatusDashboard;