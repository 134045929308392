import Logo from "./components/Logo/Logo";
import ObjectStatus from "./components/ObjectStatus/ObjectStatus";


function TopBar() {

    return (
        <div>
            <Logo clientLogo={'logo_kwidzyn.jpeg'} />
            <ObjectStatus />
        </div>
    )
}

export default TopBar