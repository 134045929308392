import { useState } from 'react'
import './ObjectStatus.css'
import StatusButton from './StatusButton'
import useObjectStatusStore from '../../../../Store/ObjectStatus'
import { ObjectStatusEnum } from '../../../../Store/ObjectStatusEnum'

function ObjectStatus() {

    const objectStatus = useObjectStatusStore((state) => state.objectStatus)

    return (
        <div >

            <div className="ObjectStatus">
                <div className="ObjectStatusValue">
                    <StatusButton className={objectStatus === ObjectStatusEnum.safe ? 'leftButton active' : 'leftButton'} textDisplay='bezpieczny' />
                    <StatusButton className={objectStatus === ObjectStatusEnum.warning ? 'centerButton active' : 'centerButton'} textDisplay='ostrzegawczy' />
                    <StatusButton className={objectStatus === ObjectStatusEnum.alert ? 'rightButton active' : 'rightButton'} textDisplay='alarmowy' />
                </div>
            </div>
        </div>
    )
}

export default ObjectStatus